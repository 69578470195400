/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { Link } from 'gatsby';
import { BASE_URL } from 'config';

class GuideConference extends PureComponent {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://starling.crowdriff.com/js/crowdriff.js';
    script.id = 'cr-init__d3c89ce7958b2149';
    script.async = true;
    document.querySelector('.gallery').appendChild(script);
  }

  render() {

    return (
      <Layout page="early-bird-offer">
        <Helmet
          title="Guide Conference 2023"
          meta={[
            {
              name: 'description', content: 'Guide Conference 2023',
            },
            { name: 'og:title', content: 'Guide Conference 2023' },
            {
              name: 'og:description', content: 'Guide Conference 2023',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/early-bird-haka-snow-tours/` }]}
        />

        <Banner modifier={'early-bird-discount-banner overlay-20'} backgroundImage="https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e017/IMG_8237-large_original.jpg">
          <h1 className="c-heading c-heading--h1">Guide Conference 2023</h1>
        </Banner>

        <section className='body-container'>
          <div className="l-container l-container--small u-text--center">
            <p>A big shoutout to everyone who joined us at our recent Haka/Intrepid guide conference in Queenstown! We had a blast and hope you all enjoyed the experience as much as we did. Together, we immersed ourselves in invaluable training and bonded as an amazing team through fun activities and teamwork.</p>
            <p>The knowledge shared during the conference has left us feeling inspired and well-equipped to provide exceptional tour experiences for our manuhiri (guests) for the coming summer season.</p>
            <p>We're immensely grateful for your presence and look forward to meeting again soon! Let's keep growing, learning, and making a positive impact in the world of travel and tourism together.</p>
            <p>Here's a snapshot from the time in Queenstown. If you have any additional images, feel free to add here: <Link
              to={'https://upload.crowdriff.com/haka-tours-guide-conference'}
              className=""
              target='_blank'
            >
              ADD
            </Link></p>
          </div>
        </section>
        <section className="mt-4" style={{ marginBottom: '2rem' }}>
          <div className="l-container u-text--center">
            <div className="gallery"></div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default GuideConference;
